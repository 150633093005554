<template>
	<div class="page-content no-padding">
        <div class="grid">
            <div class="col-12">
                <Toolbar class="p-mb-4" v-if="userCanCreate || userCanDelete">
                    <template #start>
                        <Button label="Crear" icon="pi pi-user-plus" class="p-button-success mr-2" @click="showCreateUserDialog" v-if="userCanCreate" />
                        <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger mr-2" @click="confirmDeleteSelected" :disabled="!selectedUsers || !selectedUsers.length" v-if="userCanDelete" />
                    </template>
                    <template #end>
                    </template>
                </Toolbar>
                <DataTable :value="users" v-model:selection="selectedUsers" :lazy="true" :paginator="true" class="p-datatable-gridlines" :rows="15" :totalRecords="totalRecords" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" @page="pageChanged" @rowSelect="rowSelect" :rowClass="rowClass">
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">Usuarios</h5>
                            <div class="flex flex-column md:flex-row">
                                <MultiSelect v-model="selectedRoles" :options="roles" optionLabel="role" placeholder="Filtrar rol" @change="fetchUsers" class="mb-2 mr-0 md:mb-0 md:mr-2" />
                                <Dropdown v-model="selectedStatus" :options="statuses" optionLabel="status" @change="fetchUsers" class="mb-2 mr-0 md:mb-0 md:mr-2" />
                                <Dropdown v-model="selectedEmailStatus" :options="emailStatuses" optionLabel="emailStatus" @change="fetchUsers" class="mb-2 mr-0 md:mb-0 md:mr-2" />
                                <!--<ToggleButton v-model="withTrashed" onLabel="Sin eliminados" offLabel="Ver eliminados" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" @change="fetchUsers" class="mb-2 mr-0 md:mb-0 md:mr-2" v-if="userCanRestore" />-->
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="searchQuery" placeholder="Buscar..." />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No hay usuarios.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column selectionMode="multiple" style="width: 3rem"></Column>
                    <Column header="Nombre y vivienda" field="name" style="min-width:12rem">
                        <template #body="{data}">
                            <div class="flex flex-column">
                                <span>{{data.name}}</span>
                                <span>Vivienda: {{data.extra_data?.dwelling}}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Email y tlf." field="email" style="min-width:12rem">
                        <template #body="{data}">
                            <div class="flex flex-column">
                                <a :href="'mailto:' + data.email">{{getEmail(data.email)}}</a>
                                <a :href="'tel:' + data.phone" v-if="data.phone">{{data.phone}}</a>
                            </div>
                        </template>
                    </Column>
                    <Column header="Rol" field="role" style="min-width:12rem">
                        <template #body="{data}">
                            {{data.role.name}}
                        </template>
                    </Column>
                    <Column header="Creado el" field="created_at" dataType="created_at" style="min-width:10rem">
                        <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template>
                    </Column>
                    <Column field="active" header="Estado" :filterMenuStyle="{'width':'14rem'}" style="min-width:12rem">
                        <template #body="{data}">
                            <div :class="'mb-2 customer-badge status-' + data.active">{{data.active ? 'Activo' : 'Inactivo'}}</div>
                            <div v-if="!data.email_verified_at" class="customer-badge status-false">No verificado</div>
                            <Button v-if="!data.active && !data.deleted_at" label="Activar" icon="pi pi-check-circle" class="p-button-rounded p-button-success p-button-sm mt-2" @click="activateUser(data.id)" />
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="{data}">
                            <Button v-if="userCanUpdate && !data.deleted_at" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="showUpdateUserDialog(data.id)" />
                            <Button v-if="userCanDelete && !data.deleted_at" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteUser(data)" />
                            <Button v-if="userCanRestore && data.deleted_at" icon="pi pi-undo" class="p-button-rounded p-button-success" @click="confirmRestoreUser(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="user">¿Quieres eliminar el usuario <b>{{user.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUserDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteUser" />
        </template>
    </Dialog>

    <Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="users">¿Quieres eliminar los usuarios seleccionados?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteSelectedUsers" />
        </template>
    </Dialog>

    <Dialog v-model:visible="restoreUserDialog" :style="{width: '450px'}" header="Confirmar restauración" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="user">¿Quieres restaurar el usuario <b>{{user.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="restoreUserDialog = false"/>
            <Button label="Sí" icon="pi pi-undo" class="p-button-text p-button-success" @click="restoreUser" />
        </template>
    </Dialog>

    <Dialog v-model:visible="createUserDialog" :style="{width: '450px'}" header="Nuevo usuario" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="name">Nombre</label>
                <InputText v-model="v$.newUser.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newUser.name.$error}" required />
                <div v-for="error of v$.newUser.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="email">Email</label>
                <InputText v-model.trim="v$.newUser.email.$model" id="email" type="email" class="w-full mb-2" :class="{'p-invalid': v$.newUser.email.$error}" required />
                <div v-for="error of v$.newUser.email.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <div class="grid">
                    <div class="col-12 lg:col-4">
                        <label for="dwelling">Vivienda</label>
                        <InputText v-model.trim="v$.newUser.dwelling.$model" id="dwelling" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newUser.dwelling.$error}" />
                        <div v-for="error of v$.newUser.dwelling.$errors" :key="error.$uid">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                    <div class="col-12 lg:col-8">
                        <label for="phone">Teléfono</label>
                        <InputText v-model.trim="v$.newUser.phone.$model" id="phone" class="w-full mb-2" :class="{'p-invalid': v$.newUser.phone.$error}" />
                        <div v-for="error of v$.newUser.phone.$errors" :key="error.$uid">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field mb-4">
                <label for="role">Rol</label>
                <Dropdown v-model="v$.newUser.role.$model" :options="roles" optionLabel="role" class="w-full mb-2" :class="{'p-invalid': v$.newUser.role.$error}" required />
                <div v-for="error of v$.newUser.role.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-2">
                <Button label="Generar contraseña aleatoria" icon="pi pi-lock" class="p-button-text" @click="generatePassword(false)" :disabled="working" />
            </div>
            <div class="field mb-4">
                <label for="password">Contraseña <i class="pi pi-info-circle ml-1" v-tooltip="'Mínimo 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 símbolo'" /></label>
                <Password v-model="v$.newUser.password.$model" id="password" :toggleMask="true" class="w-full mb-2" :class="{'p-invalid': v$.newUser.password.$error}" required />
                <div v-for="error of v$.newUser.password.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="password2">Repite la contraseña</label>
                <Password v-model="v$.newUser.password2.$model" id="password2" :toggleMask="true" class="w-full mb-2" :class="{'p-invalid': v$.newUser.password2.$error}" required />
                <div v-for="error of v$.newUser.password2.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>

            <div class="field mb-4">
                <div class="flex flex-row items-center">
                    <Checkbox v-model="newUser.communications" :binary="true" id="communications" class="mr-2" />
                    <label for="communications">Envío de comunicaciones</label>
                </div>
            </div>

            <div class="mt-4" v-if="registerError">
                <small class="p-error font-bold">{{ registerError }}</small>
                <ul v-if="serverErrors.length > 0">
                    <li v-for="(serverError, i) in serverErrors" v-bind:key="i"><small class="p-error font-bold">{{ serverError }}</small></li>
                </ul>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="createUserDialog = false" :disabled="working" />
            <Button label="Crear usuario" icon="pi pi-user-plus" :loading="working" class="p-button-text p-button-success" @click="createUser" :disabled="canCreateUser || working" />
        </template>
    </Dialog>

    <Dialog v-model:visible="updateUserDialog" :style="{width: '450px'}" header="Editar usuario" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="name">Nombre</label>
                <InputText v-model="v$.updateUser.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.name.$error}" required />
                <div v-for="error of v$.updateUser.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="email">Email</label>
                <InputText v-model.trim="v$.updateUser.email.$model" id="email" type="email" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.email.$error}" required />
                <div v-for="error of v$.updateUser.email.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <div class="grid">
                    <div class="col-12 lg:col-4">
                        <label for="dwelling">Vivienda</label>
                        <InputText v-model.trim="v$.updateUser.dwelling.$model" id="dwelling" type="text" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.dwelling.$error}" />
                        <div v-for="error of v$.updateUser.dwelling.$errors" :key="error.$uid">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                    <div class="col-12 lg:col-8">
                        <label for="phone">Teléfono</label>
                        <InputText v-model.trim="v$.updateUser.phone.$model" id="phone" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.phone.$error}" />
                        <div v-for="error of v$.updateUser.phone.$errors" :key="error.$uid">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field mb-4">
                <label for="role">Rol</label>
                <Dropdown v-model="v$.updateUser.role.$model" :options="roles" optionLabel="role" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.role.$error}" required />
                <div v-for="error of v$.updateUser.role.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-2">
                <Button label="Generar contraseña aleatoria" icon="pi pi-lock" class="p-button-text" @click="generatePassword(true)" :disabled="working" />
            </div>
            <div class="field mb-4">
                <label for="password">Contraseña (en blanco para no cambiarla) <i class="pi pi-info-circle ml-1" v-tooltip="'Mínimo 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 símbolo'" /></label>
                <Password v-model="v$.updateUser.password.$model" id="password" :toggleMask="true" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.password.$error}" />
                <div v-for="error of v$.updateUser.password.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field">
                <label for="password2">Repite la contraseña</label>
                <Password v-model="v$.updateUser.password2.$model" id="password2" :toggleMask="true" class="w-full mb-2" :class="{'p-invalid': v$.updateUser.password2.$error}" />
                <div v-for="error of v$.updateUser.password2.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <div class="flex flex-row items-center">
                    <InputSwitch v-model="updateUser.active" :disabled="!user.email_verified_at" id="active" />
                    <label for="active" class="ml-3">Estado</label>
                </div>
            </div>
            <div class="field justify-content-start" v-if="!user.email_verified_at">
                <strong>Este usuario no ha verificado su email</strong>
                <Button label="Reenviar correo de verificación" icon="pi pi-send" :loading="working" class="p-button-rounded p-button-help mt-2" @click="resendVerificationEmail" :disabled="working" />
            </div>

            <div class="field mb-4">
                <div class="flex flex-row items-center">
                    <Checkbox v-model="updateUser.communications" :binary="true" id="communications" class="mr-2" />
                    <label for="communications">Envío de comunicaciones</label>
                </div>
            </div>

            <div class="mt-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="updateUserDialog = false" :disabled="working" />
            <Button label="Guardar" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="saveUser" :disabled="canUpdateUser || working" />
        </template>
    </Dialog>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import AuthService from '@/service/AuthService.js';
import UserService from '@/service/UserService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, maxLength, sameAs, requiredIf } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import CustomValidators from '@/customValidators';

const compUrl = '/users';

const userRoles = [
    {role: 'Administrador', value: 3},
    {role: 'Vecino', value: 4},
];

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            users: null,
            page: 1,
            totalRecords: 0,
            withTrashed: false,
            loading: true,
            selectedUsers: [],
            deleteUserDialog: false,
            deleteUsersDialog: false,
            restoreUserDialog: false,
            createUserDialog: false,
            updateUserDialog: false,
            user: null,
            selectedRoles: userRoles,
            roles: userRoles,
            selectedStatus: {status: 'Todos', value: -1},
            statuses: [
                {status: 'Todos', value: -1},
                {status: 'Activos', value: 1},
                {status: 'Inactivos', value: 0},
            ],
            selectedEmailStatus: {emailStatus: 'Todos', value: -1},
            emailStatuses: [
                {emailStatus: 'Todos', value: -1},
                {emailStatus: 'Verificados', value: 1},
                {emailStatus: 'No verificados', value: 0},
            ],
            query: '',
            qTimeout: null,
            newUser: {
                name: '',
                email: '',
                phone: '',
                dwelling: '',
                role: '',
                password: '',
                password2: '',
                communications: true
            },
            validationMessages: {},
            working: false,
            registerError: null,
            serverErrors: [],
            updateUser: {
                name: '',
                email: '',
                phone: '',
                dwelling: '',
                role: '',
                password: '',
                password2: '',
                active: false,
                communications: true
            },
            updateError: null,
        }
    },
    authService: null,
    userService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.userService = new UserService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            newUser: {
                name: { required, maxLength: maxLength(255) },
                email: { required, email, maxLength: maxLength(255) },
                phone: { minLength: minLength(9), maxLength: maxLength(15) },
                dwelling: { minLength: minLength(2), maxLength: maxLength(255) },
                role: { required },
                password: { required, minLength: minLength(8), password: CustomValidators.password, maxLength: maxLength(255) },
                password2: { required, sameAs: sameAs(this.newUser.password), maxLength: maxLength(255) },
            },
            updateUser: {
                name: { required, maxLength: maxLength(255) },
                email: { required, email, maxLength: maxLength(255) },
                phone: { minLength: minLength(9), maxLength: maxLength(15) },
                dwelling: { minLength: minLength(2), maxLength: maxLength(255) },
                role: { required },
                password: { minLength: minLength(8), password: CustomValidators.password, maxLength: maxLength(255) },
                password2: { requiredIf: requiredIf(this.updateUser.password), sameAs: sameAs(this.updateUser.password), maxLength: maxLength(255) },
                active: { required }
            }
        };
    },
    emits: ['updateBreadcrumbs', 'refetchUsers'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Usuarios', to: compUrl, replace: true});
        this.fetchUsers();
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 2 || this.query.length === 0) {
                        this.fetchUsers();
                    }
                }, 500);
            }
        },
        userCanCreate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:create');
        },
        userCanUpdate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:update');
        },
        userCanDelete() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:delete');
        },
        userCanRestore() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('user:restore');
        },
        canCreateUser() {
            return this.v$.newUser.name.$errors.length !== 0
                || this.v$.newUser.email.$errors.length !== 0
                || this.v$.newUser.dwelling.$errors.length !== 0
                || this.v$.newUser.phone.$errors.length !== 0
                || this.v$.newUser.role.$errors.length !== 0
                || this.v$.newUser.password.$errors.length !== 0
                || this.v$.newUser.password2.$errors.length !== 0
                || this.newUser.name.length === 0
                || this.newUser.email.length === 0
                || (this.newUser.role === null || this.newUser.role === '')
                || this.newUser.password.length === 0
                || this.newUser.password2.length === 0;
        },
        canUpdateUser() {
            return this.v$.updateUser.name.$errors.length !== 0
                || this.v$.updateUser.email.$errors.length !== 0
                || this.v$.updateUser.dwelling.$errors.length !== 0
                || this.v$.updateUser.phone.$errors.length !== 0
                || this.v$.updateUser.role.$errors.length !== 0
                || this.v$.updateUser.password.$errors.length !== 0
                || this.v$.updateUser.password2.$errors.length !== 0
                || this.updateUser.name.length === 0
                || this.updateUser.email.length === 0
                || (this.updateUser.role === null || this.updateUser.role === '')
                || (this.updateUser.password.length !== 0 && this.updateUser.password2.length === 0);
        },
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        rowClass(data) {
            return data.deleted_at !== null ? 'deleted-row' : null;
        },
        rowSelect(event) {
            if (event.data.deleted_at) {
                this.selectedUsers = this.selectedUsers.filter(user => user.id !== event.data.id);
            }
        },
        confirmDeleteSelected() {
            this.deleteUsersDialog = true;
        },
        confirmDeleteUser(user) {
            this.user = user;
            this.deleteUserDialog = true;
        },
        confirmRestoreUser(user) {
            this.user = user;
            this.restoreUserDialog = true;
        },
        fetchUsers() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:list')) {
                this.loading = true;
                let roles = this.selectedRoles.map(sr => sr.value);
                let status = this.selectedStatus.value;
                let emailStatus = this.selectedEmailStatus.value;
                this.userService.fetchUsers(this.$appState.estate.id, this.page, roles, status, emailStatus, this.withTrashed, this.query).then(response => {
                    this.users = response.data.users.data;
                    this.totalRecords = response.data.users.total;
                    this.loading = false;
                    this.users.forEach(user => user.created_at = new Date(user.created_at));
                }).catch(error => {
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener los usuarios', life: 3000});
                });
            } else {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'U-00: Acceso no autorizado', life: 3000});
            }
        },
        pageChanged(event) {
            this.page = event.page + 1;
            this.fetchUsers();
        },
        deleteUser() {
            this.deleteUserDialog = false;
            this.loading = true;
            this.userService.deleteUser(this.user.id).then(() => {
                this.user = null;
                this.fetchUsers();
                this.$emit('refetchUsers');
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar el usuario', life: 3000});
            });
        },
        deleteSelectedUsers() {
            this.deleteUsersDialog = false;
            this.loading = true;
            this.userService.deleteUsers(this.selectedUsers.map(user => user.id)).then(() => {
                this.selectedUsers = [];
                this.fetchUsers();
                this.$emit('refetchUsers');
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar los usuarios', life: 3000});
            });
        },
        restoreUser() {
            this.restoreUserDialog = false;
            this.loading = true;
            this.userService.restoreUser(this.user.id).then(response => {
                this.user = null;

                if (response.data.error) {
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: response.data.error, life: 3000});
                } else {
                    this.fetchUsers();
                    this.$emit('refetchUsers');
                }
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido restaurar el usuario', life: 3000});
            });
        },
        showCreateUserDialog() {
            this.newUser = {
                name: '',
                email: '',
                dwelling: '',
                phone: '',
                role: '',
                password: '',
                password2: '',
                communications: true
            };
            this.createUserDialog = true;
        },
        createUser() {
            if (!this.working) {
                this.working = true;
                this.registerError = null;
                this.serverErrors = [];
                let w = new Worker('/pwhash-worker.js');
                let salt;

                this.authService.fetchUserNonce(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.newUser.email
                ).then(response => {
                    if (response.data.salt && response.data.nonce) {
                        salt = window.sodium.from_hex(response.data.salt);
                        w.postMessage([salt, this.newUser.password]);
                    } else {
                        this.registerError = 'ERROR R-04: Ha ocurrido un error al crear el usuario';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {email: this.newUser.email}),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.registerError = 'ERROR R-01: Ha ocurrido un error al crear el usuario';
                });

                w.onmessage = (e) => {
                    let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                    let salt_signature = window.sodium.crypto_sign_detached(salt, kp.privateKey);

                    this.userService.createUser(
                        this.$appState.estate.id,
                        this.newUser.name,
                        this.newUser.email,
                        this.newUser.dwelling,
                        this.newUser.phone,
                        this.newUser.role.value,
                        this.newUser.password,
                        this.newUser.communications,
                        window.sodium.to_hex(salt),
                        window.sodium.to_hex(kp.publicKey),
                        window.sodium.to_hex(salt_signature)
                    ).then(response => {
                        if (response.data.user) {
                            this.working = false;
                            this.createUserDialog = false;
                            this.fetchUsers();
                            this.$emit('refetchUsers');
                        } else {
                            this.registerError = 'ERROR R-03: Ha ocurrido un error al crear el usuario';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                email: this.newUser.email,
                                name: this.newUser.name,
                                dwelling: this.newUser.dwelling,
                                phone: this.newUser.phone,
                                role_id: this.newUser.role.value,
                                communications: this.newUser.communications,
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.registerError = 'ERROR R-02: Ha ocurrido un error al crear el usuario';

                        if (error.response.data && error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(ek => {
                                this.serverErrors.push(...error.response.data.errors[ek]);
                            });
                        }
                    });
                };
            }
        },
        showUpdateUserDialog(userId) {
            this.loading = true;

            this.userService.fetchUser(userId).then(response => {
                this.user = response.data.user;
                this.updateUser.name = this.user.name;
                this.updateUser.email = this.user.email;
                this.updateUser.dwelling = this.user.extra_data?.dwelling;
                this.updateUser.phone = this.user.phone;
                this.updateUser.role = userRoles.find(role => role.value === this.user.role_id);
                this.updateUser.active = this.user.active;
                this.updateUser.password = '';
                this.updateUser.password2 = '';
                this.updateUser.communications = this.user.communications;
                this.loading = false;
                this.updateUserDialog = true;
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener los datos del usuario', life: 3000});
            });
        },
        saveUser() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;

                if (this.updateUser.password.length > 0) {
                    let w = new Worker('/pwhash-worker.js');
                    let salt = window.sodium.from_hex(this.user.salt);
                    w.postMessage([salt, this.updateUser.password]);

                    w.onmessage = (e) => {
                        let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                        let salt_signature = window.sodium.crypto_sign_detached(salt, kp.privateKey);

                        this.userService.updateUser(
                            this.user.id,
                            this.updateUser.name,
                            this.updateUser.email,
                            this.updateUser.dwelling,
                            this.updateUser.phone,
                            this.updateUser.role.value,
                            this.updateUser.active,
                            this.updateUser.password,
                            this.updateUser.communications,
                            window.sodium.to_hex(salt),
                            window.sodium.to_hex(kp.publicKey),
                            window.sodium.to_hex(salt_signature)
                        ).then(response => {
                            if (response.data.user) {
                                this.working = false;
                                this.updateUserDialog = false;
                                this.user = null;
                                this.fetchUsers();
                                this.$emit('refetchUsers');
                            } else {
                                this.updateError = 'ERROR U-02: Ha ocurrido un error al actualizar el usuario';
                            }
                        }).catch(error => {
                            console.log(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request, {
                                    email: this.updateUser.email,
                                    name: this.updateUser.name,
                                    dwelling: this.updateUser.dwelling,
                                    phone: this.updateUser.phone,
                                    role_id: this.updateUser.role.value,
                                    active: this.updateUser.active,
                                    communications: this.updateUser.communications,
                                }),
                                this.errorReportService.getErrorData(error.response),
                            );
                            this.working = false;
                            this.updateError = 'ERROR U-01: Ha ocurrido un error al actualizar el usuario';
                        });
                    };
                } else {
                    this.userService.updateUser(
                        this.user.id,
                        this.updateUser.name,
                        this.updateUser.email,
                        this.updateUser.dwelling,
                        this.updateUser.phone,
                        this.updateUser.role.value,
                        this.updateUser.active,
                        null,
                        this.updateUser.communications,
                        null,
                        null,
                        null
                    ).then(response => {
                        if (response.data.user) {
                            this.working = false;
                            this.updateUserDialog = false;
                            this.user = null;
                            this.fetchUsers();
                            this.$emit('refetchUsers');
                        } else {
                            this.updateError = 'ERROR U-04: Ha ocurrido un error al actualizar el usuario';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                email: this.updateUser.email,
                                name: this.updateUser.name,
                                dwelling: this.updateUser.dwelling,
                                phone: this.updateUser.phone,
                                role_id: this.updateUser.role.value,
                                active: this.updateUser.active,
                                communications: this.updateUser.communications,
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR U-03: Ha ocurrido un error al actualizar el usuario';
                    });
                }
            }
        },
        resendVerificationEmail() {
            this.working = true;

            this.userService.resendVerificationEmail(this.user.id).then(response => {
                this.working = false;

                if (response.data.sent) {
                    this.updateError = 'Se ha enviado el correo de verificación';
                } else {
                    this.updateError = 'ERROR U-05: No se ha enviado el correo de verificación';
                }
            }).catch(error => {
                console.log(error);
                this.working = false;
                this.updateError = 'ERROR U-05: Ha ocurrido un error al enviar el correo de verificación';
            });
        },
        generatePassword(isUpdate) {
            let password = '';
            let size = 16;
            
            let characterSet = 'abcdefghijklmnopqrstuvwxyz';
            characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            characterSet += '0123456789';
            characterSet += '![]{}()%&*$#^<>~@|';

            for (let i = 0; i < size; i++) {
                password += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
            }
            
            if (!isUpdate) {
                this.newUser.password = this.newUser.password2 = password;
            } else {
                this.updateUser.password = this.updateUser.password2 = password;
            }
        },
        activateUser(userId) {
            this.working = true;

            this.userService.activateUser(userId).then(response => {
                this.working = false;

                if (response.data.activated) {
                    this.$toast.add({severity:'info', summary: 'Activado', detail: 'El usuario ha sido activado', life: 3000});
                    this.fetchUsers();
                    this.$emit('refetchUsers');
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido activar el usuario', life: 3000});
                }
            }).catch(error => {
                console.log(error);
                this.working = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido activar el usuario', life: 3000});
            });
        },
        getEmail(email) {
            const p = email.indexOf('|');

            if (p === -1) {
                return email;
            } else {
                return email.substring(p + 1);
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-datatable .p-datatable-tbody > tr.deleted-row) {
        background-color: var(--red-50);
    }

	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-true {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-false {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
